import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';
import { ReactComponent as AccountEncrypted } from 'static/icons/account-encrypted.svg';

import { block } from 'utils/block';
import { getDateString } from 'utils/time';

import './AppointmentMobile.scss';

const b = block('appointment-mobile');

type Props = {
    startTime: number;
    appointmentId: string;
    onCancel: () => void;
}

export const AppointmentMobile: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
    startTime,
    appointmentId,
    onCancel,
    className = '',
    ...rest
}) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const appointmentDate = new Date(startTime);
    const date = getDateString(appointmentDate, language);
    return (
        <article
            className={b().mix(className)}
            id={`appointment-checkbox-${appointmentId}`}
            {...rest}
        >
            <AccountEncrypted
                className={b('icon')}
                aria-hidden
            />
            <header
                className={b('header')}
            >
                <p className={b('title')}>
                    {date}
                </p>
                <p className={b('subtitle')}>
                    {t('appointment_checkbox_subtitle')}
                </p>
            </header>
            <p
                className={b('cancel')}
                onClick={onCancel}
                tabIndex={0}
                role="button"
            >
                {t('appointment_checkbox_cancel')}
            </p>
        </article>
    );
};
