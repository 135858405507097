import { ChallengeType , Locale } from '@amzn/ring-rse-scheduling-service-typescript-client';
import { client } from 'api/client';

import { HttpStatusCode } from 'constants/httpCodes';
import { setIsAuthorized } from 'utils/cookies';
import { getResponseData } from 'utils/url';

/**
 * This function initialises the sending of the OTP code to the phone number
 * Response:
 * {
 *     "challengeType": "otp:sms"
 *     "challengeId": "random string generated by server",
 * }
 * @param phone
 * @param otpLocale
 */
export async function sendCodeViaSMS(phone: string, otpLocale: Locale) {
    return await getResponseData(client.initiateAuthV1({
        challengeType: ChallengeType.Otpsms,
        identifier: phone,
        otpLocale,
    }));
}

/**
 * This function initialises the verification of the code previously sent to the user's phone
 * @param code
 * @param challengeId - JWT token derived from sendCodeViaSMS
 * @param phone
 */
export async function sendOTPCode(code: string, challengeId: string, phone: string): Promise<HttpStatusCode> {
    const response = await getResponseData(client.completeAuthV1({
        challengeType: ChallengeType.Otpsms,
        identifier: phone,
        challengeProof: code,
        challengeId,
    }));
    if (response.status === HttpStatusCode.OK) {
        setIsAuthorized();
    }
    return response.status;
}
