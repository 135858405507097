import { FC, KeyboardEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Button } from '@amzn/ring-safe-web';
import { getAppointments } from 'api/appointment';
import { sendOTPCode } from 'api/verification';
import { useRoute } from 'hooks/useRoute';
import i18n from 'i18next';
import { ReactComponent as MobilePhone } from 'static/images/mobile-phone.svg';

import { FooterPortal } from 'components/Footer';
import { PageBody } from 'components/Page';
import { resetSMSVerificationForm, SMSVerificationForm } from 'components/SMSVerificationForm';
import { DEFAULT_LANGUAGE, DEFAULT_RETRY_COUNT } from 'constants/common';
import { HttpStatusCode } from 'constants/httpCodes';
import { block } from 'utils/block';
import { asyncRetry } from 'utils/common';
import { getRootContainer } from 'utils/layout';
import { formatPhoneNumberForBackend, getFullPhoneNumber } from 'utils/phone';
import { useAppDispatch, useAppSelector } from 'utils/store';
import { findServiceByLocale, getOTPLocale } from 'utils/tenant';

import { PhoneType } from 'types/common';
import { LanguageCode } from 'types/language';
import { ROUTE } from 'types/route';
import { SMSVerificationFormStatus } from 'types/state';

import { receiveChangeIdThunk } from './AccountVerificationPageSlice';

import './AccountVerificationPage.scss';

const b = block('account-verification-page');

const SUSPEND_BEFORE_REDIRECT = 1000;

export const AccountVerificationPage: FC = () => {
    const { t } = useTranslation();
    const { changeRoute } = useRoute();
    const dispatch = useAppDispatch();

    const phone = useAppSelector((state) => (state.personDetailsForm.phone.value)) as PhoneType;

    const stringPhone = formatPhoneNumberForBackend(phone);

    const code = useAppSelector((state) => state.smsVerificationForm.code.value!);
    const challengeId = useAppSelector((state) => state.challengeId!);

    const isDisabled = useAppSelector((state) => state.smsVerificationForm.status !== SMSVerificationFormStatus.SUCCESS);

    const services = useAppSelector(state => state.tenantConfig?.services, shallowEqual);
    const otpLocale =  !services ? DEFAULT_LANGUAGE : findServiceByLocale(services, i18n.language as LanguageCode)?.otpLocale || DEFAULT_LANGUAGE;

    useEffect(() => {
        const timerId = setTimeout(onButtonClick, SUSPEND_BEFORE_REDIRECT);
        return () => clearTimeout(timerId);
    }, [isDisabled]);


    useEffect(() => {
        return () => {
            dispatch(resetSMSVerificationForm({}));
        };
    }, []);

    const sendSMS = useCallback(() => dispatch(receiveChangeIdThunk(stringPhone, getOTPLocale(otpLocale), DEFAULT_RETRY_COUNT)), [stringPhone]);
    const onSubmit = () => asyncRetry(sendOTPCode, DEFAULT_RETRY_COUNT)(code, challengeId, stringPhone);

    const onButtonClick = async () => {
        if (!isDisabled) {
            const { data, status } = await getAppointments();
            if (status === HttpStatusCode.OK && data.appointments?.length > 0) {
                changeRoute(ROUTE.APPOINTMENT_CONFIRMATION);
            } else {
                changeRoute(ROUTE.APPOINTMENT_BOOKING);
            }
        }
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onButtonClick();
        }
    };

    const onChangePhoneLinkClick = () => {
        changeRoute(ROUTE.CONTACT_INFORMATION);
    };

    return (
        <PageBody className={b()}>
            <SMSVerificationForm
                phone={getFullPhoneNumber(phone)}
                onSubmit={onSubmit}
                sendSMS={sendSMS}
                className={b('form')}
                onChangePhoneLinkClick={onChangePhoneLinkClick}
                id='account-verification-page-form'
                onKeyDown={onKeyDown}
            />
            <div className={b('illustration')}>
                <MobilePhone
                    className={b('svg')}
                    aria-hidden
                />
            </div>
            <FooterPortal container={getRootContainer()}>
                <Button
                    color='primary'
                    size='m'
                    variant='filled'
                    onClick={onButtonClick}
                    id="account-verification-page-redirect-button"
                    disabled={isDisabled}
                >
                    {t('pages_interests_button')}
                </Button>
            </FooterPortal>
        </PageBody>
    );
};
