/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';

export const logger = new KatalLogger({
    url: 'https://u3pac7yi5c.execute-api.us-east-1.amazonaws.com/prod/v1/log',
    recordMetrics: false,
    useXMLHttpRequest: true,
    logThreshold: Level.INFO,
    logToConsole: true,
    context: {
        referrer: document.referrer,
        initialPath: window.location.pathname,
        cookieEnabled: navigator.cookieEnabled,
    },
});
logger.addErrorListener();
