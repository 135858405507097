import { ChangeEvent, FormEvent, forwardRef, HTMLAttributes, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, PhoneInput } from '@amzn/ring-safe-web';
import { useLanguage } from 'hooks/useLanguage';
import { useMobileScroll } from 'hooks/useMobileScroll';

import { SUPPORTED_COUNTRIES } from 'constants/common';
import { block } from 'utils/block';
import { getFirstErrorFieldName } from 'utils/form';
import { getUserPhoneCodeFromLang } from 'utils/phone';
import { useAppDispatch, useAppSelector } from 'utils/store';
import { isMobile } from 'utils/view';

import { PersonalDetailsFormFieldType } from 'types/state';

import { changePersonDetailsForm, validatePersonDetailsField } from './PersonDetailsFormSlice';

import './PersonDetailsForm.scss';

const b = block('person-details-form');

type Props = {
    id: string;
}

export const PersonDetailsForm = forwardRef<HTMLFormElement, Props & HTMLAttributes<HTMLFormElement>>(({
    className = '',
    id,
    onSubmit,
    ...rest
}, ref) => {
    const { t } = useTranslation();
    const form = useAppSelector((state) => state.personDetailsForm);
    const errorFieldName = getFirstErrorFieldName(form);
    const { language } = useLanguage();

    const dispatch = useAppDispatch();

    const firstNameRef = useRef<null | HTMLInputElement>(null);
    const lastNameRef = useRef<null | HTMLInputElement>(null);
    const phoneRef = useRef<null | HTMLInputElement>(null);
    useMobileScroll();

    useEffect(() => {
        firstNameRef.current?.focus();
    }, []);

    const focusOnFirstIncorrectField = () => {
        const dict = {
            firstName: firstNameRef,
            lastName: lastNameRef,
            phone: phoneRef,
        };
        if (errorFieldName) {
            const input = dict[errorFieldName].current;
            input?.scrollIntoView({ behavior: 'smooth' });
            input?.focus();
        }
    };

    const onFieldChange = (field: PersonalDetailsFormFieldType) => (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            changePersonDetailsForm({
                field,
                data: {
                    value: e.target.value,
                    message: undefined,
                    status: 'default',
                },
            }),
        );
    };

    const onPhoneChange = (code: number, number = '') => {
        dispatch(
            changePersonDetailsForm({
                field: 'phone',
                data: {
                    value: { code, number },
                    message: undefined,
                    status: 'default',
                },
            }),
        );
    };

    const onFocus = (field: PersonalDetailsFormFieldType) => (e: ChangeEvent<HTMLInputElement>) => {
        onFieldChange(field)(e);
    };


    const onBlur = (field: PersonalDetailsFormFieldType) => () => {
        dispatch(validatePersonDetailsField(field));
    };

    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit?.(e);
        focusOnFirstIncorrectField();
    };

    return (
        <form
            className={b().mix(className)}
            id={id}
            ref={ref}
            onSubmit={onFormSubmit}
            onInvalid={onFormSubmit}
            {...rest}
        >
            <Input
                id={`${id}-name`}
                label={t('form_name_label')}
                message={t(form.firstName.message || 'form_name_message')}
                disabled={false}
                placeholder={t('form_name_placeholder')}
                value={form.firstName.value}
                required
                status={form.firstName.status}
                onChange={onFieldChange('firstName')}
                className={b('first-name')}
                type='text'
                onBlur={onBlur('firstName')}
                onFocus={onFocus('firstName')}
                name='firstName'
                ref={firstNameRef}
            />
            <Input
                id={`${id}-lastname`}
                label={t('form_lastname_label')}
                message={t(form.lastName.message || 'form_lastname_message')}
                disabled={false}
                placeholder={t('form_lastname_placeholder')}
                value={form.lastName.value}
                status={form.lastName.status}
                onChange={onFieldChange('lastName')}
                className={b('last-name')}
                type='text'
                onBlur={onBlur('lastName')}
                onFocus={onFocus('lastName')}
                name='lastName'
                ref={lastNameRef}
            />
            <PhoneInput
                id={`${id}-phone`}
                label={t('form_phone_label')}
                message={t(form.phone.message || 'form_phone_message')}
                disabled={false}
                value={form.phone.value?.number}
                required
                status={form.phone.status}
                onChangeCallback={onPhoneChange}
                className={b('phone')}
                type='tel'
                name='phone'
                ref={phoneRef}
                checkedPhoneCode={form.phone.value
                    ? form.phone.value.code
                    : getUserPhoneCodeFromLang(SUPPORTED_COUNTRIES, language)}
                alpha2Codes={SUPPORTED_COUNTRIES}
                isAbsolute={false}
                closeOnFocusOutside={!isMobile()}
                closeOnClickOutside={true}
            />
            <p className={b('agreement')}>
                {t('form_agreement')}
            </p>
            {/* it is needed to catch "return" key type */}
            <button type="submit" className={b('button')} aria-hidden tabIndex={-1} />
        </form>
    );
});
