import { Locale } from '@amzn/ring-rse-scheduling-service-typescript-client';
import { Action, createSlice, PayloadAction, SliceCaseReducers, ThunkAction } from '@reduxjs/toolkit';
import { sendCodeViaSMS } from 'api/verification';

import { HttpStatusCode } from 'constants/httpCodes';
import { asyncRetry } from 'utils/common';

import { ChallengeIdType, StateType } from 'types/state';

export const challengeIdSlice = createSlice<ChallengeIdType, SliceCaseReducers<ChallengeIdType>, string>({
    name: 'challengeId',
    initialState: null,
    reducers: {
        setChallengeId: (state, action: PayloadAction<string | null>) => {
            return action.payload;
        },
    },
});

export function receiveChangeIdThunk(phone: string, locale: Locale, retryCount: number): ThunkAction<Promise<HttpStatusCode>, StateType, void, Action> {
    return async (dispatch) => {
        const response = await (asyncRetry(sendCodeViaSMS, retryCount)(phone, locale));
        if (response.status === HttpStatusCode.OK) {
            dispatch(setChallengeId(response.data?.challengeId));
        }
        return response.status;
    };
}

export const { setChallengeId } = challengeIdSlice.actions;
export const challengeIdReducer = challengeIdSlice.reducer;
