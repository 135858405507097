import { Locale } from '@amzn/ring-rse-scheduling-service-typescript-client';

import { currentStage, STAGE } from 'constants/stage';

import { LanguageCode } from 'types/language';
import { Service } from 'types/state';

/**
 * This function retrieves languages from an array of services.
 * @param {Service[]} services - The array of services.
 * @returns {LanguageCode[]} - The array of language codes.
 */
export function getLanguagesFromServices(services: Service[])  {
    return services.map((service) => service.locale) as LanguageCode[];
}

/**
 * This function finds a service in the array based on the provided locale.
 * @param {Service[]} services - The array of services.
 * @param {LanguageCode} locale - The locale to search for.
 * @returns {Service | undefined} - The found service or undefined if not found.
 */
export function findServiceByLocale(services: Service[], locale: LanguageCode) {
    return services.find((service) => service.locale === locale);
}

/**
 * This function finds a timeResourceId in services by the passed language.
 * @param {Service[]} services - The array of services.
 * @param {LanguageCode} language - The language to search for.
 * @returns {string} - The found timeResourceId or storeId for the current currentStage if not found.
 */
export function getTimeResourceId(services: Service[], language: LanguageCode) {
    return findServiceByLocale(services, language)?.timeResourceId || STAGE[currentStage].storeId;
}

/**
 * This function maps string locale value to Locale instance.
 * @param {string} otpLocale - string value of the locale.
 * @returns {Locale} - the corresponding Locale value to the locale string value.
 */
export function getOTPLocale(otpLocale:string) {
    switch (otpLocale) {
        case 'it-IT' : return Locale.ItIt;
        case 'de-DE' : return Locale.DeDe;
        case 'fr-FR' : return Locale.FrFr;
        case 'es-ES' : return Locale.EsEs;
        default : return Locale.EnGb;
    }
}
