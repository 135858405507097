import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary, NotificationProvider } from '@amzn/ring-safe-web';

import { App } from 'components/App';
import { DisplayLocation } from 'content/DisplayLocation';
import { store } from 'utils/store';

import { ErrorBoundaryPage } from './pages/ErrorBoundaryPage';

import './index.global.scss';

import 'utils/i18n';
import 'utils/logger';

const logger = {
    error: () => {},
};


// The Suspense component causes React to display the fallback until
// the i18n translation strings have been loaded.
createRoot(document.getElementById('root')!).render(
    <Suspense>
        <div id='root-content'>
            <ErrorBoundary errorPage={<ErrorBoundaryPage />} logger={logger}>
                <BrowserRouter>
                    <Provider store={store}>
                        <DisplayLocation>
                            <NotificationProvider>
                                <App />
                            </NotificationProvider>
                        </DisplayLocation>
                    </Provider>
                </BrowserRouter>
            </ErrorBoundary>
        </div>
    </Suspense>,
);
